<template>
  <div>
    <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
      <h4 class="col-12 col-xl-4 mb-2 mb-xl-0 font-weight-bold">
        點數異動紀錄
      </h4>
      <div
        class="
          col-12 col-xl-8
          d-flex
          flex-xl-row
          align-items-start align-items-xl-center
          justify-content-end
        "
      >
        <b-button
          v-if="showAddButton"
          class="flex-shrink-0 mb-0 ml-2 btn"
          variant="primary"
          v-b-modal.modal-add
        >
          手動入點
        </b-button>
        <b-button
          class="flex-shrink-0 mb-0 ml-2 btn"
          variant="primary"
          @click="handleExport"
          v-if="query.merchant_id"
        >
          <i class="fa fa-plus"></i>匯出 excel
        </b-button>
        <template v-if="showFilter">
          <b-form-select
            v-model="query.merchant_id"
            :options="merchants"
            :disabled="merchants.length == 2"
            @change="handlePageReset"
            class="mx-3"
          ></b-form-select>
          <b-input-group v-if="query.merchant_id">
            <b-form-input
              placeholder="搜尋會員名稱、手機、外部編碼"
              v-model="query.keyword"
              @keyup.enter="handleSearch"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handleSearch">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      :items="pointLogs"
      :fields="fields"
      :busy="isLoading"
      :no-local-sorting="true"
      @sort-changed="handleSort"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(customer_name)="data">
        {{ data.item.customer_name | hiddenString(20) }}
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
      </template>
      <template #cell(actions)="data">
        <b-button
          class="ml-2"
          variant="inverse-info"
          :to="{
            name: 'PointDetail',
            params: {
              pointLogId: data.item.id,
            },
          }"
          >查看點數內容</b-button
        >
      </template>
    </b-table>

    <CustomPagination
      :currentPage="query.page"
      :totalRows="total"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />

    <b-modal id="modal-add" title="請問要增加多少點？">
      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="點數"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="point.point"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label-cols="12"
        label-cols-lg="2"
        label-size="sm"
        label="備註"
      >
        <b-form-input
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="point.memo"
        ></b-form-input>
      </b-form-group>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="addPoint">
          確定
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import pointLogApi from "@/apis/point-log";
import pointApi from "@/apis/point";
import merchantApi from "@/apis/merchant";
import store from "@/store";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperMerchantId: {
      type: String,
      default: null,
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      pointLogs: [],
      merchantId: null,
      merchants: [{ value: null, text: "請選擇" }],
      perPage: 15,
      currentPage: 1,
      total: 0,
      point: {
        point: null,
        memo: null,
      },
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        sort_by: "created_at",
        order_by: "desc",
        merchant_id: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
    fields() {
      let fields = [
        {
          key: "customer_name",
          label: "LINE 名稱",
          sortable: true,
        },
        {
          key: "type",
          label: "類型",
        },
        {
          key: "batch_total_points",
          label: "異動點數",
        },
        {
          key: "target_type",
          label: "異動來源",
        },
        {
          key: "created_at",
          label: "異動時間",
          sortable: true,
        },
        {
          key: "actions",
          label: "管理",
        },
      ];

      if (this.excludeFields.length > 0) {
        fields = fields.filter(
          (field) => !this.excludeFields.includes(field.key)
        );
      }

      return fields;
    },
    showAddButton() {
      return (
        this.upperCustomerId &&
        this.$permissions.has(this.$permissions.consts.POINT_LIST_CREATE)
      );
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  mounted() {
    this.fetchMerchants();
    this.initialized = true;
  },
  async created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    "$route.query": {
      handler(newQuery) {
        if (!this.initialized) return;
        if (Object.keys(newQuery).length !== 0) {
          this.query = { ...newQuery };
        }
        this.fetchPointLogs();
      },
      deep: true,
    },
  },
  methods: {
    async fetchPointLogs() {
      try {
        this.isLoading = true;
        const params = {
          // 若 upperCustomerId 為 null，api 參數會被轉成 null 字串，所以這邊多一個判斷將 null 變成空字串
          customer_id: this.upperCustomerId || "",
          ...this.query,
        };
        const { data } = await pointLogApi.getPointLogs(
          this.upperMerchantId || this.query.merchant_id,
          params
        );
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.pointLogs = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleExport() {
      if (this.query.merchant_id) {
        const searchParams = new URLSearchParams({
          sort_by: this.sortBy,
          order_by: this.orderBy,
          keyword: this.query.keyword || "",
          token: store.state.auth.token,
        });
        const url = `${process.env.VUE_APP_API_BASE_URL}/admin/organizations/${this.organization.id}/merchants/${this.query.merchant_id}/point-logs/export?${searchParams}`;

        window.open(url);
      }
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        const defaultMerchant = data.data.find((merchant) => merchant.type === this.defaultMerchantType) ??
          data.data.find((merchant) => merchant.type === "LINE") ;

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        await this.fetchPointLogs();
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handleSearch() {
      if (this.query.merchant_id) {
        this.handlePageReset();
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    async addPoint() {
      try {
        this.point.customer_id = this.upperCustomerId;
        await pointApi.storePoint(this.upperMerchantId, this.point);
        this.$swal.fire({
          title: "新增成功",
          type: "success",
        });
      } catch (error) {
        this.$swal.fire({
          title: "新增失敗",
          type: "error",
        });
        console.log(error);
      } finally {
        this.$bvModal.hide("modal-add");
        this.fetchPointLogs();
      }
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>
